
import { defineComponent } from "vue";
import ECSIA from "@/components/ECSIA/ECSIA.vue";
import OurStory from "@/components/About/OurStory.vue";

export default defineComponent({
  components: {
    ECSIA,
    OurStory,
  },
  data() {
    return {
      story: {
        type: String,
        title: "Our Story",
        paragraph:
          "Glynn Barber founded ECSIA® on the idea that produce grown from traditional agriculture could be better. In 2007, Glynn’s son was diagnosed with Schizophrenia. The mission to improve his son’s health became a tall order for Glynn, consuming years of his life and every resource he had, but nothing is unattainable to a parent with determination to heal their child. That is how ECSIA® was born.<br/><br/>After many trials in his garage and garden, Glynn established a new standard of growing, called Environmentally Controlled Sustainable Integrated Agriculture (ECSIA®).<br/><br/>The truest testament to the nutrition that ECSIA® can produce is found in the success of his son’s schizophrenia management that occurred after eating what was grown out of the first ECSIA® system.",
        image: "Glynn.jpg",
      },
    };
  },
});
